body {
  margin: 0;
  font-family: ui-sans-serif, system-ui, -system-ui, -apple-system, BlinkMacSystemFont, halogen, 'IBM Plex Sans KR', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  border: none;
  background-color: transparent;
  font-size: inherit;
}

button:hover {
  cursor: pointer;
}

/* Fonts */

/* Halogen Regular
font-family: halogen, sans-serif;
font-weight: 400;
font-style: normal;

Halogen Regular Oblique
font-family: halogen, sans-serif;
font-weight: 400;
font-style: italic;

Halogen Thin
font-family: halogen, sans-serif;
font-weight: 200;
font-style: normal;

Halogen Thin Oblique
font-family: halogen, sans-serif;
font-weight: 200;
font-style: italic; */

/* Noh Optique Display Bold
font-family: optique-display, sans-serif;
font-weight: 700;
font-style: normal;

Noh Optique Display Regular
font-family: optique-display, sans-serif;
font-weight: 400;
font-style: normal; */

.menu-opened {
  overflow: hidden;
}
